.closeIcon {
  border-radius: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.divider {
  margin: 0 24px 0 24px;
  width: 100%;
  background: #F6F6F6;
  height: 1px;
}

.userName {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  width: 220px;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  font-size: 18px;
  line-height: 27px;
  color: #292D32;
}

.userConfig {
  display: flex;
  justify-content: space-between;
}

.list {
  width: 651px;
}

.userList {
  display: flex;
  flex-direction: column;
  row-gap: 21px;
  overflow-y: auto;
  height: 420px;
  scrollbar-color: #dbdbdb #ffffff;
  scrollbar-width: thin;
  padding: 0 24px 16px 24px;
}

.userList_mobile {
  height: 236px;
}

@media (max-width: 1900px) {
  .userList{
    height: 170px;
  }
}

@media (max-width: 1600px) {
  .userList{
    height: 160px;
  }
}

@media (max-width: 1450px) {
  .userList{
    height: 130px;
  }
}

@media (max-width: 1350px) {
  .userList{
    height: 80px;
    padding: 19px 16px 19px 16px;
    overflow-y: auto;
  }
}

@media (max-width: 1064px) {
  .userList{
    height: 160px;
    padding: 19px 16px 19px 16px;
    overflow-y: auto;
  }
}

.userList::-webkit-scrollbar {
  background-color: #ffffff;
  width: 7px;
}

.userList::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 10px;
}

.actionList {
  display: flex;
  column-gap: 8px;
}

.input {
  margin: 0 24px 16px 24px;
}

.description {
  margin: 0 24px 16px 24px;
  display: flex;
  justify-content: flex-end;
  column-gap: 9px;
}

.descriptionText {
  width: 32px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  color: #888888;
  height: 20px;
}

.inviteRoom {
  margin: 0 24px 29px 24px;
  display: flex;
  flex-direction: column;
  column-gap: 13px;
}

.inviteText {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #292D32;
  text-align: left;
}

.clipboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dublicateIcon {
  cursor: pointer;
  display: flex;
  align-content: center;
}

.link {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
  text-overflow: ellipsis;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  color: #888888;
}

.userActionButton {
  cursor: pointer;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  transition-duration: .2s;
  align-items: center;
}

.userActionButton:hover {
  transition-duration: .2s;
  background: rgba(87, 162, 255, 0.8);
}

.userActionButton:active {
  transition-duration: .2s;
  background: #57A2FF;
}

.volumeOn {
  background: rgba(87, 162, 255, 0.3);
}

.disable {
  background: #F6F6F6;
  cursor: not-allowed;
  transition-duration: .2s;
}

.disable path {
  fill: #C4C4C4;
  transition-duration: .2s;
}

.disable:hover {
  background: #F6F6F6;
}

.disable:active {
  background: #F6F6F6;
}

.isUser {
  background: rgba(87, 162, 255, 0.3);
}

